<script lang="ts">
export default {
    name: 'IconPerson',
    props: {
        /**
         * Width
         */
        width: {
            type: String,
            default: '24px',
            required: false,
        },
        /**
         * Height
         */
        height: {
            type: String,
            default: '24px',
            required: false,
        },
    },
};
</script>
<template>
    <svg
        :style="{
            height: height,
            width: width,
        }"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor">
        <path
            d="M17.982 7.027c0 3.315-2.712 6.028-6.027 6.028s-6.027-2.713-6.027-6.028C5.928 3.712 8.64 1 11.955 1s6.027 2.712 6.027 6.027Z"
            fill="none" />
        <path
            d="M11.955 13.055c3.315 0 6.027-2.713 6.027-6.028C17.982 3.712 15.27 1 11.955 1S5.928 3.712 5.928 7.027s2.712 6.028 6.027 6.028Zm0-10.046a4.03 4.03 0 0 1 4.018 4.018 4.03 4.03 0 0 1-4.018 4.019 4.03 4.03 0 0 1-4.018-4.019 4.03 4.03 0 0 1 4.018-4.018Z" />
        <path
            d="m21.698 19.685-.401-3.114a3.843 3.843 0 0 0-1.306-2.512c-.703-.602-1.708-1.004-2.612-1.004h-.402c-.602 0-1.004.402-1.004 1.004 0 .603.402 1.005 1.004 1.005h.402c.502 0 1.005.2 1.306.502.402.302.603.804.703 1.206l.402 3.114v.402c0 .1-.1.301-.201.401-.1.101-.2.201-.301.201-.1.1-.302.1-.402.1H5.225c-.1 0-.302 0-.402-.1-.1-.1-.201-.1-.302-.2-.1-.101-.2-.201-.2-.402 0-.1-.1-.302 0-.402l.401-3.114a2.4 2.4 0 0 1 .704-1.306c.401-.302.803-.502 1.305-.502h.402c.603 0 1.005-.402 1.005-1.005 0-.603-.402-1.005-1.005-1.005h-.502c-1.005 0-1.909.402-2.612 1.005a3.842 3.842 0 0 0-1.306 2.511l-.401 3.114c-.1.402 0 .905.1 1.306.1.402.301.804.603 1.105.301.302.602.603 1.004.704.402.2.804.301 1.206.301h13.56c.402 0 .804-.1 1.206-.301.401-.201.703-.402 1.004-.804.302-.301.502-.703.603-1.105.1-.301.2-.703.1-1.105Z" />
    </svg>
</template>
